import React, { useEffect, useState } from 'react';
import { useSearchParams, NavLink } from "react-router-dom";
import LoggedLayout from '../../layouts/LoggedLayout';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import Details from 'pages/Conteos/Details';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as ConteosService } from 'services/Conteos';

const TableResponsive = styled.div` 
	@media (min-width: 992px) {
		overflow-x: visible;
	}
`;

const Table = styled.table`
	tr {

		th {

			&:nth-child(1) {
			}

			&:nth-child(2) {
				width: 220px;
			}

			&:nth-child(3) {
				width: 120px;
			}

			&:nth-child(4) {
				width: 50px;
			}
		}
	}
`;

const Filters = styled.div`
	display: flex;
	justify-content: end;

	@media (max-width: 1200px) {
		justify-content: center;
		flex-direction: column;

		& > *,
		.empowered-selector {
			width: 100% !important;
			display: block !important;
		}

		& > * {
			margin-bottom: 10px;
		}
	}
`;

const EmpoweredSelectorWrapper = styled.div` 
	margin-right: 10px;

	.empowered-selector {
		width: 200px;
		
		.empowered-selector_label {
			height: 28px;
			white-space: nowrap;
			overflow: hidden;

			&:hover {
				background: white;
			}

			.remove-icon {
				background: white;
				border-radius: 50%;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Conteos() {
	const [queryParams] = useSearchParams();

	let [forceReload, setForceReload] = useState(null);
	let [conteos, setConteos] = useState([]);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('created_at');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [details, setDetails] = useState({});
	let [station, setStation] = useState(null);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setConteos({...conteos, data: undefined});
		_setPage(page);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);

			// Remove details
			delete details[rowId];
			setDetails({...details});
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const loadStations = (input, callback) => {
		axios.get('/api/stations/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});

			callback(formatted);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		let getConteos = async () => {
			let conteos = await ConteosService.list({
				page: page,
				sort: sortField,
				direction: sortDirection,
				station_id: station?.id
			});
			setSkeletonRows(conteos?.data?.length);
			setConteos({...conteos});
		}
		getConteos();

		let reloadInterval = setInterval(() => {
			getConteos();
		}, 5000);

		return function cleanup() {
			clearInterval(reloadInterval);
		}
	}, [forceReload, page, sortField, sortDirection, station]);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader>
					<span className="header-column header-title">Conteos</span>
					<span className="header-column header-icon-end">
						<i className="bi bi-printer text-plenoil-black"></i>
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-black text-white p-2">
					<div className="row">
						<div className="col-md-2 text-uppercase">
							Registro de conteos
						</div>
						<Filters className="col-md-10">
							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback) => loadStations(input, callback)}
									onChange={(value, text) => setStation(value)}
									timeout={200}
									label={station?.name}
									value={station?.id ?? null}
									placeholder="Estación"
								/>
							</EmpoweredSelectorWrapper>
						</Filters>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<TableResponsive className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'station.name'} onClick={() => sortTableClick('station.name')}>Estación</ThSortable>
								<th>Competencia</th>
								<th>Fecha</th>
								<ThSortable direction={sortDirection} active={sortField === 'sent_at'} onClick={() => sortTableClick('sent_at')}>Estado</ThSortable>
							</tr>
						</thead>
						<tbody>
							{ conteos.data?.map((el, idx) => {
								return (
									<React.Fragment key={el.id}>
										<tr className={'has-details ' + (detailsOpened.includes(el.id) ? 'opened' : '')} onClick={() => toggleDetails(el.id)}>
											<td>
												<div className="d-flex justify-content-between">
													<span>{el.station?.name}</span>
												</div>
											</td>
											<td>
												{el.data?.competitor?.name ?? ''}
											</td>
											<td>
												{el.data?.date_execution ? moment(el.data?.date_execution).format('DD-MM-YYYY') : ''}
											</td>
											<td>
												{el.sent_at ? <span className="badge bg-success">enviado</span> : <span className="badge bg-danger">no enviado</span>}
											</td>
										</tr>
										{ detailsOpened.includes(el.id) &&
											<Details 
												conteo={el}
											/>
										}
									</React.Fragment>
								)
							}) }

							{ conteos.data && !conteos.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ conteos.data === undefined && <TrSkeleton rows={skeletonRows} columns={4} /> }
						</tbody>
					</Table>
				</TableResponsive>
			</div>

			<div className="col-md-12 text-end mb-3">
				<div className="d-inline-block">
					<Paginator
						min={1}
						current={conteos?.current_page}
						max={conteos?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</div>
			</div>
		</LoggedLayout>
	);
}