import axios from 'axios';

export default class Prices {

	static axiosCancelToken = axios.CancelToken.source();

	//--------------------------------------------------------------------------------------//
	//                                 Competition Section                                  //
	//--------------------------------------------------------------------------------------//

	static getStations = (sortField, sortDirection, search) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices/stations-list', {
			params: {
				sort: sortField,
				direction: sortDirection,
				search: search
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static getPrices = (stations_octan_ids) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices/list', {
			params: {
				stations_octan_ids: stations_octan_ids ? stations_octan_ids.join(',') : ''
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static getCompetitionPrices = (stations_octan_ids) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices/competition-list', {
			params: {
				stations_octan_ids: stations_octan_ids ? stations_octan_ids.join(',') : ''
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getStationCompetitionSchedule = (station_id) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices/station-competition-schedule/' + station_id, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static saveStationCompetitionSchedule = (data) => {
		this.newAxiosCancelToken();

		return axios.post('api/prices/station-competition-schedule/save', data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}

	static saveStationCompetitionVisitPrice = (visit_id, type, price) => {
		this.newAxiosCancelToken();

		return axios.post('api/prices/station-competition-visit/update-price', {
			id: visit_id,
			type: type,
			price: price
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}

	static getStationCompetitionVisitPhoto = (visit_id) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices/station-competition-visit/get-photo/' + visit_id, {
			params: {},
			responseType: 'blob',
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}



	//--------------------------------------------------------------------------------------//
	//                                    Checks Section                                    //
	//--------------------------------------------------------------------------------------//

	static getChecksList = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices-check/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static addCheck = (data) => {
		this.newAxiosCancelToken();

		return axios.post('api/prices-check/add', data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}

	static updateCheckStatus = (checkId, type, status) => {
		this.newAxiosCancelToken();

		return axios.post('api/prices-check/update-status', {
			check_id: checkId,
			type: type,
			status: status
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}

	static deleteChecksBulk = (checksIds) => {
		this.newAxiosCancelToken();

		return axios.post('api/prices-check/delete-bulk', checksIds, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}
	//  --------------------------------------------------------------------------------------



	//--------------------------------------------------------------------------------------//
	//                                    Zones section                                     //
	//--------------------------------------------------------------------------------------//
	static getZonesList = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices-zones/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getZone = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices-zones/get/' + id, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static saveZone = (data) => {
		this.newAxiosCancelToken();

		return axios.post('api/prices-zones/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}

	static deleteZone = (id) => {
		this.newAxiosCancelToken();

		return axios.delete('api/prices-zones/delete/' + id, {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}






	//--------------------------------------------------------------------------------------//
	//                                  App Prices Section                                  //
	//--------------------------------------------------------------------------------------//
	static getAppPricesList = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/prices-app/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	//  --------------------------------------------------------------------------------------







	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}