import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { default as SuppliesService } from 'services/Supplies';

const Tr = styled.tr`
	
	.line-row {
		margin-bottom: 10px;
		padding-bottom: 10px;
		
		&:not(:last-of-type) {
			border-bottom: 1px solid gray;
		}
		
		.top {
			display: flex;

			.number {
				width: 30px;
				overflow: hidden;
			}

			.name {
				.code {
					width: 100px;
					color: gray;
					font-size: 10px;
				}
			}
		}

		.qty {
			padding-left: 30px;

			input {
				display: inline-block;
				width: 100px;
			}
		}

		.sent-status {
			padding-left: 30px;
		}

		.sent-error {
			margin-left: 30px;
			white-space: pre-wrap;
			word-break: break-all;
		}

		.retry-button-wrapper {
			margin-left: 30px;
			white-space: pre-wrap;
			word-break: break-all;

			button {
				width: 100px;
				padding: 0;
				margin-top: 10px;
			}
		}
	}
`;

export default function Details(props) {
	let id = props.id;
	let [forceReload, setForceReload] = useState(null);
	let [order, setOrder] = useState({});

	useEffect(() => {
		let getData = async () => {
			let data = await SuppliesService.getOrder(id);
			setOrder(data);
		}
		getData();

		return function cleanup() {
			SuppliesService.cancel();
		}
	}, [id, forceReload]);

	const updateQty = async (line_id, qty) => {
		let result = await SuppliesService.updateOrderLineQty(line_id, qty);

		toast.dismiss(); // Hide all opened toast
		if ( result?.status !== 1 ) {
			toast.error('Ha ocurrido un error al actualizar las unidades');
		} else {
			toast.success('Unidades actualizadas');
		}
	}

	const approveOrder = async () => {
		let result = await SuppliesService.approveOrder(order.id);

		toast.dismiss(); // Hide all opened toast
		if ( result?.status !== 1 ) {
			toast.error('Ha ocurrido un error al aprobar el pedido');
		} else {
			toast.success('Pedido aprobado');
		}

		setForceReload(Math.random());
	}

	const cancelOrder = async () => {
		let result = await SuppliesService.cancelOrder(order.id);

		toast.dismiss(); // Hide all opened toast
		if ( result?.status !== 1 ) {
			toast.error('Ha ocurrido un error al cancelar el pedido');
		} else {
			toast.success('Pedido cancelado');
		}

		setForceReload(Math.random());
	}

	const retrySendLine = async (orderLineId) => {
		let result = await SuppliesService.retrySendLine(orderLineId);

		if ( result?.status !== 1 ) {
			toast.error('Ha ocurrido un error al reintentar el envío');
		} else {
			toast.success('Envío reintentado');
		}

		setForceReload(Math.random());
	}

	return (
		<Tr className="details">
			<td colSpan="100%">
				<div className="row">
					<div className="col-md-11">
						{order?.lines?.map((el, idx) => {
							let showRetryButton = false;

							// Determine sent status
							let sentStatus = null;
							if ( !el.sent_at && !el.sent_log && el.on_date_to_send ) sentStatus = <span className="text-primary">En proceso de envío</span> 
							if ( el.sent_log && el.sent_log.error ) {
								showRetryButton = true;

								if ( !el.sent_at && el.on_date_to_send ) {
									sentStatus = <span className="text-danger">Error en el envío. Se procederá a enviar de nuevo automática.</span>
									showRetryButton = false;
								} else {
									sentStatus = <span className="text-danger">Error en el envío. Envío cancelado.</span>
								}
							}
							if ( !el.sent_at && !el.sent_log && !el.on_date_to_send ) sentStatus = <span className="text-warning">Envío cancelado</span>
							if ( el.sent_at && el.sent_log && !el.sent_log.error ) sentStatus = <span className="text-success">Enviado</span>

							return (
								<div className="line-row" key={idx}>
									<div className="top">
										<div className="number">{idx+1}.</div>
										<div className="name">
											{el.name}
											<div className="code">{el.code}</div>
										</div>
									</div>
									<div className="qty">
										{order.status === 0 ? 
											<input type="number" className="form-control form-control-sm" defaultValue={el.qty} onChange={(e) => updateQty(el.id, e.target.value)} />
											:
											el.qty
										}
										&nbsp;unidades
									</div>
									<div className="sent-status">
										{sentStatus}
									</div>
									{ el.sent_log?.error &&
										<div className="sent-error">
											<code>{JSON.stringify(el.sent_log)}</code>
										</div>
									}
									{ (showRetryButton && el.on_date_to_send) &&
										<div className="retry-button-wrapper">
											<button className="btn btn-sm btn-secondary btn-sm" onClick={() => retrySendLine(el.id)}>Reintentar</button>
										</div>
									}
								</div>
							);
						})}
					</div>
					<div className="col-md-1 text-end">
						{ order.status === 0 &&
							<React.Fragment>
								<button className="btn btn-sm btn-success mb-3 w-100" onClick={() => approveOrder()}>Aprobar</button>
								<div></div>
								<button className="btn btn-sm btn-danger w-100" onClick={() => cancelOrder()}>Cancelar</button>
							</React.Fragment>
						}
					</div>
				</div>
			</td>
		</Tr>
	);
}


