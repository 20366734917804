import axios from 'axios';

export default class Conteos {

	static axiosCancelToken = axios.CancelToken.source();

	static list = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/conteos/admin-list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getConteo = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/conteos/admin-get/' + id, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}