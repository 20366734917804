import React, { useEffect, useState, useRef } from "react";
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { toast } from "react-toastify";
import { default as ClockingsService } from 'services/Clockings';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	&.modal {

	}
`;

const PinModal = (props) => {
	const modalRef = useRef(null);

    const dispenser = props.dispenser;
	const closeCallback = props.closeCallback;

    const [pin, setPin] = useState(null);
	const [newPin, setNewPin] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
	}, [closeCallback]);

    useEffect(() => {
        let getPin = async () => {
            setLoading(true);
            let disp = await ClockingsService.getDispenser(dispenser.id);
            setLoading(false);
            setPin(disp?.pin);
        }
        getPin()

        return () => {
            ClockingsService.cancel();
        }
    }, []);

	const closeModal = () => {
		// Hide modal
		Modal.getInstance(modalRef.current).hide();
		props.closeCallback();
	}

	const saveData = async () => {
		setLoading(true);

        let result = await ClockingsService.setDispenserNewPin(dispenser.id, newPin);
        if (result.status) {
            closeModal();
        } else {
            toast.error('Error al guardar el PIN');
        }
		
		setLoading(false);
	}

    let newPinValid = newPin && newPin.length >= 4 && newPin.length <= 8;

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">APP Expendedores PIN</h5>
						<button type="button" className="btn-close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								<div className="mb-3">
									<label>PIN actual</label>
									<input type="text" className="form-control form-control-sm" value={pin ?? ''} onChange={(e) => null} readOnly={true} />
								</div>
							</div>
							<div className="col-md-12">
								<div className="mb-3">
									<label>Nuevo PIN</label>
									<input type="text" className="form-control form-control-sm" value={newPin ?? ''} onChange={(e) => setNewPin(e.target.value)} />
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button className="btn btn-sm btn-plenoil-primary" disabled={loading || !newPinValid} onClick={() => loading ? null : saveData()}>{loading ? 'Guardando...' : 'Guardar'}</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}

export default PinModal;