import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import LoggedLayout from 'layouts/LoggedLayout';
import styled from 'styled-components';
import { hasCapability } from 'helpers/user';
import { default as PricesService } from 'services/Prices';

const Table = styled.table`
	th,
	td {
		&:nth-child(3) {
			width: 20px;
			vertical-align: middle;
		}
	}
`;

export default function PricesZones() {
	let [zones, setZones] = useState([]);

	useEffect(() => {
		const getZones = async () => {
			let zones = await PricesService.getZonesList({no_paginate: true});
			setZones([...zones]);
		}
		getZones();

		return function cleanup() {
			PricesService.cancel();
        }
	}, []);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				{ hasCapability('prices', 'add') &&
					<NavLink to="/prices-zones/add" className="btn btn-sm btn-light float-end">Añadir zona</NavLink>
				}
				<h4 className="mb-0">Listado de zonas para precios</h4>
				<small>Las zonas se utilizan para agrupar estaciones para el panel de precios </small>
			</div>

			<div className="col-md-12 mb-3">
				<div className="table-responsive">
					<Table className="table table-bordered bg-white">
						<thead>
							<tr className="bg-plenoil-red text-white">
								<th>Nombre</th>
								<th>Estaciones</th>
								<th></th>
							</tr>
						</thead>
						<tbody className="border-top-0">
							{ zones.map((el, idx) => {
								return (
									<tr key={idx}>
										<td>{el.name}</td>
										<td>{ el.stations?.map((mEl, idx) => {
											return <div key={idx}>{ mEl.name }</div>;
										}) }</td>
										<td>
											<NavLink to={'/prices-zones/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
										</td>
									</tr>
								)
							}) }
							{ !zones.length && <tr key="empty"><td colSpan="100%">No hay zonas</td></tr> }
						</tbody>
					</Table>
				</div>
			</div>
		</LoggedLayout>
	);
}


