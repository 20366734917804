import React, { useState } from 'react';
import styled from 'styled-components';
import DateDropdown from 'components/DateDropdown';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as StationsService } from 'services/Stations';

const Styled = styled.div`
    padding: 15px;

    background: var(--bs-gray-200);

    textarea {
        resize: none;
    }

    label {
        display: block;
        color: var(--plenoil-gray);
        font-size: 10px;
    }

    #new-destination-title {
        cursor: default;
        max-width: 150px;
    }

    #date-dropdown {
        width: 100%;

        & > .dropdown {
            width: 100%;

            & > .btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: white;
                white-space: nowrap;
                font-size: 13px;
                width: 100%;
                text-align: left;

                small {
                    align-self: flex-end;
                }
            }
        }
    }
`;

export default function NewDestination(props) {
    const onCancel = props.onCancel;

    const [station, setStation] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    
    const setDatesRange = (range) => {
		setDateFrom(range.from);
		setDateTo(range.to);
	}

    const loadStations = async (input, callback) => {
        let stations = await StationsService.getList({
            search: input,
            no_paginate: true
        });
        
        callback(stations?.map(el => {
            return {
                value: el,
                label: el.name
            }
        }));
    }

    return (
        <Styled>
            <span className="btn btn-sm btn-plenoil-primary py-0 w-50" id="new-destination-title"><i className="bi bi-person"></i> NUEVO DESTINO</span>

            <div className="row w-100 mt-4">
                <div className="col-md-2">
                    <div className="">
                        <label>MOTIVO</label>
                        <input type="text" className="form-control form-control-sm" />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="">
                        <label>ESTACIÓN</label>
                        <EmpoweredSelector
                            load={(input, callback) => loadStations(input, callback)}
                            onChange={(value, text) => setStation(value)}
                            timeout={200}
                            label={station?.name}
                            value={station?.id ?? null}
                            placeholder="Estación"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div>
                        <label>FECHA</label>
                        <DateDropdown 
                            id="date-dropdown"
                            defaultPeriod={'today'} 
                            onChange={(period, range) => setDatesRange(range)}
                            onlyDates={true}
                            showPeriods={false}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="">
                                <label>HORA ENTRADA</label>
                                <input type="time" className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="">
                                <label>HORA SALIDA</label>
                                <input type="time" className="form-control form-control-sm" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="">
                        <label>NOTAS</label> 
                        <textarea type="text" className="form-control form-control-sm" rows="5"></textarea>
                    </div>
                </div>
                <div className="col-md-2 pt-3">
                    <button className="btn btn-sm btn-plenoil-primary py-0">CONFIRMAR</button>
                    <div></div>
                    <button className="btn btn-sm btn-danger py-0" onClick={onCancel}>CANCELAR</button>
                </div>
            </div>
        </Styled>
    );
}