import styled from 'styled-components';

export default function Details(props) {
	let conteo = props.conteo;

	let json = conteo?.json ? JSON.stringify(conteo?.json) : null;
	let reply = conteo?.reply ? JSON.stringify(conteo?.reply) : null;

	return (
		<tr className="details">
			<td colSpan="100%">
				<div className="row">
					<div className="col-md-6">
						<h4>JSON</h4>
						<pre style={{whiteSpace: 'wrap'}}><code>{json}</code></pre>
					</div>
					<div className="col-md-6">
						<h4>Respuesta</h4>
						<pre style={{whiteSpace: 'wrap'}}><code>{reply}</code></pre>
					</div>
				</div>
			</td>
		</tr>
	);
}


