import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { getUser } from 'helpers/user';
import ChatService from 'services/Chat';

const MessageStyled = styled.div`
    .message {
        position: relative;
        background: white;
        border-radius: 5px;
        margin-bottom: 10px;
        font-size: 12px;
        padding: 5px;

        &.left {
            margin-right: 30px;
        }

        &.right {
            margin-left: 30px;

            .meta {
                .sender-name {
                    float: right;
                    text-align: right;

                    span {
                        background: var(--plenoil-blue);
                    }
                }

                .date {
                    text-align: left;
                    float: left;
                }

                .msg {
                    text-align: right;
                }
            }
        }

        .meta {
            font-size: 10px;

            .sender-name {
                width: 60%;
                float: left;
                text-align: left;
                
                span {
                    border-radius: 10px;
                    padding: 2px 10px;
                    background: var(--plenoil-orange);
                    color: white;
                }
            }

            .date {
                width: 40%;
                float: right;
                font-size: 9px;
                text-align: right;
            }
        }

        .msg {
            clear: both;
            margin-top: 5px;
            padding: 5px;

            .img-placeholder {
                background: var(--plenoil-blue-superlight);
                color: var(--bs-gray-600);
                font-size: 30px;
                width: 100%;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
            }

            img {
                border-radius: 3px;
                width: 100%;
                cursor: pointer;
            }
        }
    }
`;

const Message = React.forwardRef((props, ref) => {
    let ownRef = useRef(null);
    let downloadingImageRef = useRef(false);
    let checkVisibleOnViewPortIntervalRef = useRef(null);
    let [img, setImg] = useState(null);

    // If message has ref (from parent forwarded), scroll into view because it means that is last message
    // If img is set, set into view also
    useEffect(() => {
        if ( (ref && ref.current) ) ref.current.scrollIntoView();
    }, [ref, img]);

    useEffect(() => {
        if ( !props.has_image ) return;

        const downloadImage = async () => {
            downloadingImageRef.current = true;

            let img = await ChatService.downloadImage(props.id);
            if ( img.dataurl ) setImg(img.dataurl);

            downloadingImageRef.current = false;
        }

        checkVisibleOnViewPortIntervalRef.current = setInterval(() => {
            if ( !ownRef.current ) return; // Fix to avoid error when chat component is closed with opened messages window

            let elementBoundingClientRef = ownRef.current.getBoundingClientRect();
            if ( 
                (
                    elementBoundingClientRef.y > props.parentBoundingClientRect.y 
                    || 
                    elementBoundingClientRef.y + elementBoundingClientRef.height > props.parentBoundingClientRect.y
                )   
                &&
                (
                    elementBoundingClientRef.y < props.parentBoundingClientRect.y + props.parentBoundingClientRect.height
                    ||
                    elementBoundingClientRef.y + elementBoundingClientRef.height < props.parentBoundingClientRect.y + props.parentBoundingClientRect.height
                )
                &&
                !downloadingImageRef.current
            ) {
                if ( !img ) downloadImage();
                else clearInterval(checkVisibleOnViewPortIntervalRef.current);
            }
        }, 500);

        return function cleanup() {
            downloadingImageRef.current = false;
            clearInterval(checkVisibleOnViewPortIntervalRef.current);
        }
    }, [props.id, props.has_image, props.parentBoundingClientRect, img]);

    const openPhoto = async (message_id) => {
        let img = await ChatService.downloadImage(props.id, 'full');
        if ( img.dataurl ) {
            let blob = await (await fetch(img.dataurl)).blob();
            const url = window.URL.createObjectURL(blob, {type: 'image/jpg'});
	        window.open(url);
        }
    }

	return (
		<MessageStyled ref={ownRef}>
            <div ref={ref} className={'message ' + (props.user_id === getUser()?.id ? 'right' : 'left')}>
                <div className="meta">
                    <div className="sender-name"><span>{props.user?.name}</span></div>
                    <div className="date">{moment(props.created_at).format('HH:mm')} | {moment(props.created_at).format('DD-MM-YYYY')}</div>
                </div>
                <div className="msg">
                    {props.has_image ?
                        <React.Fragment>
                            {img && <img src={img} alt="chat-pht" onClick={() => openPhoto(props.id)} /> }
                            {!img && <div className="img-placeholder"><i className="bi bi-card-image"></i></div> }
                        </React.Fragment>
                        :
                        props.message
                    }
                </div>
            </div>
		</MessageStyled>
	);
});

export default Message;