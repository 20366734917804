import React, { useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getUser, setUser, hasCapability } from 'helpers/user';
import useStore from "store";
import Chat from 'components/Chat';

import Login from "pages/Login";
import Logout from "pages/Logout";
import Dashboard from "pages/Dashboard";
import Probes from "pages/Probes";
import MiddlewareStations from "pages/MiddlewareStations";
import Zones from "pages/Zones";
import ZonesForm from "pages/ZonesForm";
import Users from "pages/Users";
import UsersForm from "pages/UsersForm";
import TankerTrucks from "pages/TankerTrucks";
import TankerTrucksForm from "pages/TankerTrucksForm";
import Prices from "pages/Prices";
import PricesCheck from "pages/PricesCheck";
import PricesApp from "pages/PricesApp";
import PricesCheckForm from "pages/PricesCheckForm";
import PricesZones from "pages/PricesZones";
import PricesZonesForm from "pages/PricesZonesForm";
import Supplies from "pages/Supplies";
import SuppliesAllowedCodes from "pages/Supplies/AllowedCodes";
import SuppliesBCOrdersToCheck from "pages/SuppliesBCOrdersToCheck";
import SuppliesPlanning from "pages/SuppliesPlanning";
import SuppliesStationsWashSwitch from "pages/SuppliesStationsWashSwitch";
import ProbesWarnings from "pages/ProbesWarnings";
import Conteos from "pages/Conteos";
import PricesIndicusWebserviceSends from "pages/PricesIndicusWebserviceSends";
import ClockingsResponsibles from "pages/Clockings/Responsibles";
import ClockingsDispensers from "pages/Clockings/Dispensers";
import ClockingsDispenser from "pages/Clockings/Dispenser";
import ClockingsDispensersSchedules from "pages/Clockings/DispensersSchedules";
import ClockingsStationsMatching from "pages/Clockings/StationsMatching";

const MainContainer = styled.div`
	height: 100%;
`;

function App() {
	const location = useLocation();

    const forceReload = useStore(state => state.forceReload);
	
	// Axios global
    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = '/';
    
    // Axios interceptors
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        // Check unauthorized error
        if ( error.response && error.response.status === 401 ) {
            setUser(null, true);
			window.location.reload();
        }

        // Default
        return Promise.reject(error);
    });

    // Get user & community and update
    useEffect(() => {
    	const updateLoggedUser = async () => {
    		// Get logged user
	    	let user = getUser();
	    	if ( !user || !user.id ) return;

	    	// Get from server
	    	let user_from_server = null;
	    	let getUserFromServer = () => {
	    		return axios.get('/api/get-authuser-data').then((response) => {
					user_from_server = response.data;
			  	});
	    	}
	    	await getUserFromServer();

	    	// Compare and update
	    	if ( JSON.stringify(user) !== JSON.stringify(user_from_server) ) {
	    		setUser({...user, ...user_from_server}, true);
	    	}
    	} 
    	updateLoggedUser();
    }, [forceReload]);
	
	return (
		<MainContainer forceReload={forceReload}>
			{ (!getUser() && location.pathname !== '/login') &&
				<Navigate to="/login" />
			}

			<Routes>
				<Route path="/" element={<Dashboard />} />
				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />

				{ hasCapability('probes') &&
					<React.Fragment>
						<Route path="probes" element={<Probes />} />
						<Route path="middleware-stations" element={<MiddlewareStations />} />
						<Route path="zones" element={<Zones />} />
						<Route path="zones/add" element={<ZonesForm />} />
						<Route path="zones/edit/:id" element={<ZonesForm />} />
						<Route path="/probes-warnings" element={<ProbesWarnings />} />
					</React.Fragment>
				}

				{ hasCapability('users') &&
					<React.Fragment>
						<Route path="users" element={<Users />} />
						<Route path="users/add" element={<UsersForm />} />
						<Route path="users/edit/:id" element={<UsersForm />} />
					</React.Fragment>
				}

				{ hasCapability('tankertrucks') &&
					<React.Fragment>
						<Route path="tankertrucks" element={<TankerTrucks />} />
						<Route path="tankertrucks/status/:status" element={<TankerTrucks />} />
						<Route path="tankertrucks/add-manual" element={<TankerTrucksForm />} />
					</React.Fragment>
				}

				{ hasCapability('prices') &&
					<React.Fragment>
						<Route path="prices" element={<Prices />} />
						<Route path="prices-check" element={<PricesCheck />} />
						<Route path="prices-app" element={<PricesApp />} />
						<Route path="prices-check/add" element={<PricesCheckForm />} />
						<Route path="prices-zones" element={<PricesZones />} />
						<Route path="prices-zones/add" element={<PricesZonesForm />} />
						<Route path="prices-zones/edit/:id" element={<PricesZonesForm />} />
						<Route path="prices-indicus-webservice-sends" element={<PricesIndicusWebserviceSends />} />
					</React.Fragment>
				}

				{ hasCapability('supplies') &&
					<React.Fragment>
						<Route path="supplies/station" element={<Supplies type="station" />} />
						<Route path="supplies/wash" element={<Supplies type="wash" />} />
						<Route path="supplies/allowed-codes/station" element={<SuppliesAllowedCodes type="station" />} />
						<Route path="supplies/allowed-codes/wash" element={<SuppliesAllowedCodes type="wash" />} />
						<Route path="supplies/bc-orderstc/station" element={<SuppliesBCOrdersToCheck type="station" />} />
						<Route path="supplies/bc-orderstc/wash" element={<SuppliesBCOrdersToCheck type="wash" />} />
						<Route path="supplies/planning/station" element={<SuppliesPlanning type="station" />} />
						<Route path="supplies/planning/wash" element={<SuppliesPlanning type="wash" />} />
						<Route path="supplies/wash-switch" element={<SuppliesStationsWashSwitch />} />
					</React.Fragment>
				}
				}

				{ (hasCapability('clockings_primary') || hasCapability('clockings_secondary')) &&
					<React.Fragment>
						<Route path="clockings/responsibles" element={<ClockingsResponsibles />} />
						<Route path="clockings/dispensers" element={<ClockingsDispensers />} />
						<Route path="clockings/dispenser/:id" element={<ClockingsDispenser />} />
						<Route path="clockings/dispensers-schedules" element={<ClockingsDispensersSchedules />} />
						<Route path="clockings/stations-matching" element={<ClockingsStationsMatching />} />
					</React.Fragment>
				}

				{ hasCapability('conteos') &&
					<React.Fragment>
						<Route path="conteos" element={<Conteos />} />
					</React.Fragment>
				}
			</Routes>

			{getUser()?.id && <Chat />}

			<ToastContainer
				position="bottom-right"
				autoClose={2000}
			/>
		</MainContainer>
	);
}

export default App;