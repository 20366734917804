import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import LoggedLayout from '../../layouts/LoggedLayout';
import PageHeader from 'components/PageHeader';
import { default as SuppliesService } from 'services/Supplies';

export default function AllowedCodes(props) {
	let type = props.type;

	let [forceReload, setForceReload] = useState(null);
	let [codes, setCodes] = useState(null);
	let [newCode, setNewCode] = useState(null);

	useEffect(() => {
		let getData = async () => {
			let codes = await SuppliesService.getAllowedCodesList(type);
			setCodes(codes);
		}
		getData();
	}, [forceReload, type]);

	const addNewCode = async () => {
		let result = await SuppliesService.addAllowedCode(newCode, type);
		if ( result?.status === 1 ) {
			toast.success('Código añadido');
			setCodes(null);
			setNewCode(null);
			setForceReload(Math.random());
		} else {
			toast.error('Error al añadir el código');
		}
	}

	const removeCode = async (id) => {
		const c = window.confirm('¿Seguro que quieres eliminar el código?');
		if ( !c ) return false;

		let result = await SuppliesService.removeAllowedCode(id);
		if ( result?.status === 1 ) {
			toast.success('Código borrado');
			setForceReload(Math.random());
		} else {
			toast.error('Error al borrar el código');
		}
	}
	
	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader>
					<span className="header-column header-title">Suministros</span>
					<span className="header-column header-icon-end">
						<i className="bi bi-printer text-plenoil-brown-light"></i>
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-brown-light text-white p-2">
					<div className="row">
						<div className="col-md-4 text-uppercase">
							Códigos de artículos de suministros de <u>{type === 'station' ? 'Estaciónes' : 'Lavaderos'}</u>
						</div>
						<div className="col-md-8 text-end">
							<NavLink to={-1} className="btn btn-sm btn-light">volver</NavLink>
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-4">
				<div className="input-group input-group-sm mb-3">
					<span className="input-group-text">Añadir código</span>
					<input type="text" className="form-control form-control-sm" value={newCode ?? ''} onChange={(e) => setNewCode(e.target.value)} />
					<button className="btn btn-outline-secondary" onClick={addNewCode} disabled={!newCode?.length}>Añadir</button>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<table className="table table-sm table-bordered">
					<thead>
						<tr>
							<td>Código</td>
						</tr>
					</thead>
					<tbody>
						{codes && codes.map((el, idx) => {
							return (
								<tr key={idx}>
									<td>
										<button className="btn btn-link text-danger" onClick={() => removeCode(el.id)}><i className="bi bi-x"></i></button>
										{el.code}
									</td>
								</tr>
							);
						})}
						{codes?.length === 0 && <tr><td colSpan="100%">No hay códigos disponibles</td></tr>}
						{!codes && <tr><td colSpan="100%">Cargando...</td></tr>}
					</tbody>
				</table>
			</div>
		</LoggedLayout>
	);
}