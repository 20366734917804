import axios from 'axios';

export default class Clockings {

	static axiosCancelToken = axios.CancelToken.source();

	static setDispenserNewPin = (dispenserId, newPin) => {
		this.newAxiosCancelToken();

		return axios.post('api/clockings/set-dispenser-new-pin', {dispenser_id: dispenserId, new_pin: newPin}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static saveStationsMatching = (matchings) => {
		this.newAxiosCancelToken();

		return axios.post('api/clockings/save-stations-matching', {matchings: matchings}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getStationsMatching = () => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/stations-matching', {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getClockingCompanyTree = (search) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/tree', {
			params: {
				search: search
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getDispenserSchedule = (dispenserId, date) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/get-dispenser-schedule/' + dispenserId + '/' + date, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getDispenserSchedules = (dispenserId, dateFrom, dateTo, params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/dispenser-schedules/' + dispenserId + '/' + dateFrom + '/' + dateTo, {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getDispenserNextScheduleDate = (dispenserId, fromDate) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/get-dispenser-next-schedule-date/' + dispenserId + '/' + fromDate, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getDispenser = (id, params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/dispenser/' + id, {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getDispensers = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/dispensers-list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getDispensersSchedules = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/dispensers-schedules', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getResponsibles = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/responsibles-list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getResponsible = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/clockings/responsible/' + id, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}