import styled from "styled-components";
import moment from "moment";

const TableAlertStyled = styled.div`
    background: ${props => props.color};
    color: ${props => props.color ? 'white' : 'inherit'};
    padding: 2px 5px;
    border-radius: 5px;
`;

export default function(props) {
    const interval = props.interval;
    const what = props.what;
    const key = 'chronometer_' + what;

    let alertColor = null;

    if ( what === 'start_at' ) {
        if ( interval?.alerts?.notEntered ) {
            alertColor = 'var(--plenoil-red)';
        }

        if ( interval?.alerts?.isLateEnter ) {
            alertColor = 'var(--plenoil-orange)';
        }
    }

    if ( what === 'end_at' ) {
        if ( interval?.alerts?.isEarlyLeave ) {
            alertColor = 'var(--plenoil-orange)';
        }

        if ( interval?.alerts?.isLateLeave ) {
            alertColor = 'var(--plenoil-red)';
        }
    }

    let time = '--:--:--';
    if ( interval && interval[key] ) {
        time = moment('2024-12-10 ' + interval[key]).format('HH:mm');
    }

    return (
        <TableAlertStyled color={alertColor}>
            {time}
        </TableAlertStyled>
    );
}