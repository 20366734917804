import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import PageHeader from 'components/PageHeader';
import EmpoweredSelector from 'components/EmpoweredSelector';
import StationDetails from './StationDetails';
import { default as MiddlewareStationsService } from 'services/MiddlewareStations';
import { default as ProbesService } from 'services/Probes';

const Filters = styled.div`
	display: flex;
	justify-content: end;

	@media (max-width: 1200px) {
		justify-content: center;
		flex-direction: column;

		& > *,
		.empowered-selector {
			width: 100% !important;
			display: block !important;
		}

		& > * {
			margin-bottom: 10px;
		}
	}
`;

const EmpoweredSelectorWrapper = styled.div` 
	margin-right: 10px;

	.empowered-selector {
		width: 200px;
		
		.empowered-selector_label {
			height: 28px;
			white-space: nowrap;
			overflow: hidden;

			&:hover {
				background: white;
			}

			.remove-icon {
				background: white;
				border-radius: 50%;
			}
		}
	}
`;

const MAX_CONCURRENT_REQUESTS = 5;

export default function Probes() {
	let [stations, setStations] = useState([]);
	let [zone, _setZone] = useState({});
	let [station, _setStation] = useState({});

	let concurrentRequestAvailableRef = useRef(MAX_CONCURRENT_REQUESTS);

	const setZone = (data) => {
		_setStation({});
		_setZone(data);
		setStations([]);
	}

	const setStation = (data) => {
		_setZone({});
		_setStation(data);
		setStations([]);
	}

	useEffect(() => {
		// Only get data if exists filters
		if ( zone.id === undefined && station.id === undefined ) {
			setStations([]);
			return;
		}

		concurrentRequestAvailableRef.current = MAX_CONCURRENT_REQUESTS; // Reset when filter changed

		// Get zone and station id
		let zone_id = zone?.id;
		let station_id = station?.id;

		if ( zone_id === -1 ) zone_id = null;
		if ( station_id === -1 ) station_id = null;

		const getStations = async () => {
			let result = await MiddlewareStationsService.list({
				zone_id: zone_id,
				station_id: station_id,
			});

			setStations(result);		    	
		}
		getStations();
	}, [zone?.id, station?.id]);

	const loadZones = async (input, callback) => {
		let result = await ProbesService.listZones(input);

		let formatted = result.map((el) => {
			return {
				value: el.id, 
				label: el.name
			};
		});
		formatted.unshift({
			value: -1,
			label: <div className="text-center">Ver todas</div>
		});

		callback(formatted);
	}

	const loadStations = async (input, callback) => {
		let result = await MiddlewareStationsService.list({
			search: input
		});

		let formatted = result.map((el) => {
			return {
				value: el.id, 
				label: el.name
			};
		});
		formatted.unshift({
			value: -1,
			label: <div className="text-center">Ver todas</div>
		});

		callback(formatted);
	}

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader>
					<span className="header-column header-title">SONDAS</span>
					<span className="header-column header-icon-end">
						<i className="bi bi-card-list text-plenoil-black"></i>
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-orange text-white p-2">
					<div className="row">
						<div className="col-md-2 text-uppercase">
						
						</div>
						<Filters className="col-md-10">
							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback) => loadStations(input, callback)}
									onChange={(value, text) => setStation(value ? {id: value, name: text} : {})}
									timeout={200}
									label={station.name ?? 'Estaciones'}
									placeholder={'Estaciones'}
									value={station.id ?? null}
								/>
							</EmpoweredSelectorWrapper>
							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback) => loadZones(input, callback)}
									onChange={(value, text) => setZone(value ? {id: value, name: text} : {})}
									timeout={200}
									label={zone.name ?? 'Zonas'}
									placeholder={'Zonas'}
									value={zone.id ?? null}
								/>
							</EmpoweredSelectorWrapper>
						</Filters>
					</div>
				</div>
			</div>

			{stations?.length > 0 && 
				<div className="col-md-12">
					<div className="row mx-0">
						{stations.map((el, index) => (
							<div className="col-md-4" key={index}>
								<StationDetails 
									station={el}
									
									availableSlot={() => concurrentRequestAvailableRef.current > 0}
									getSlot={() => concurrentRequestAvailableRef.current--}
									freeSlot={() => concurrentRequestAvailableRef.current++}	
								/>
							</div>
						))}
					</div>
				</div>
			}

			{/* <div className="col-md-4">
				<StationDetails 
					station={{
						id: 1,
						octan_id: 20,
						name: 'coslada dev'
					}} 

					availableSlot={() => concurrentRequestAvailableRef.current > 0}
					getSlot={() => concurrentRequestAvailableRef.current--}
					freeSlot={() => concurrentRequestAvailableRef.current++}	
				/>
			</div> */}
		</LoggedLayout>
	);
}


