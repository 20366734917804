import styled from 'styled-components';

const StyledResume = styled.td`
    padding: 10px;
    text-transform: uppercase;

    .with-alert {
        color: red;
    }

    div {
        display: flex;
        
        span {
            display: inline-flex;

            &:first-of-type {
                width: 60%;    
            }

            &:last-of-type {
                width: 40%;    
            }
        }
    }

    hr {
        margin-top: 5px;
        margin-bottom: 5px;
    }
`;

export default function(props) {
    const schedules = props.schedules;

    let total_spected_time_in_seconds = schedules.reduce((acc, schedule) => acc + schedule.spected_time_in_seconds, 0);
    let total_chronometers_total_in_seconds = schedules.reduce((acc, schedule) => acc + (schedule.chronometers_total_in_seconds > 0 ? schedule.chronometers_total_in_seconds : 0), 0);
    let balance_in_seconds = total_spected_time_in_seconds - total_chronometers_total_in_seconds;

    let balanceNegative = balance_in_seconds < total_spected_time_in_seconds;

    let delays = 0;
    let norecords = 0;
    schedules.forEach(schedule => {
        schedule.intervals.forEach(interval => {
            if ( interval.alerts.isLateEnter ) {
                delays++;
            }
            if ( interval.alerts.notEntered ) {
                norecords++;
            }
        });
    });

    return (
        <StyledResume rowSpan={schedules.length} id="resume">
            <div>
                <span>Horas esperadas:</span>
                <span>{total_spected_time_in_seconds ? formatSecondsToHMS(total_spected_time_in_seconds) : '--:--:--'}</span>
            </div>
            <div>
                <span>Horas trabajadas:</span>
                <span>{total_chronometers_total_in_seconds ? formatSecondsToHMS(total_chronometers_total_in_seconds) : '--:--:--'}</span>
            </div>
            <div>
                <span>Balance:</span>
                <span className={balanceNegative ? 'with-alert' : ''}>{balanceNegative ? '-' : ''}{formatSecondsToHMS(balance_in_seconds)}</span>
            </div>
            <hr />
            <div>
                <span>Tardanzas:</span>
                <span className={delays > 0 ? 'with-alert' : ''}>{delays}</span>
            </div>
            <div>
                <span>Sin registro:</span>
                <span className={norecords > 0 ? 'with-alert' : ''}>{norecords}</span>
            </div>
        </StyledResume>
    );
}

const formatSecondsToHMS = (seconds) => {
    const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${h}:${m}:${s}`;
}