import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import EmpoweredSelector from 'components/EmpoweredSelector';
import FieldSmallComment from 'components/FieldSmallComment';
import LabeledFrame from 'components/LabeledFrame';
import { jsonToArray } from 'helpers/generic';
import { getUser, hasCapability } from 'helpers/user';
import useStore from "store";

const CapabilitiesTable = styled.table`
	font-size: 13px;
	vertical-align: middle;

	thead {
		&.type {
			background: var(--plenoil-blue);
			color: white;
		}

		th {
			font-weight: normal;

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				width: 50px;
				text-align: center;
			}
		}
	}

	tbody {
		td {
			&:not(:nth-child(1)) {
				text-align: center;
			}

			.form-switch {
				display: inline-flex;
				margin-right: -5px;
				margin-bottom: -5px;
			}
		}
	}
`;

const StationsWrapper = styled.div`

`;

const Station = styled.div`
	position: relative;
	display: inline-flex;
	background: var(--bs-light);
	margin: 5px 5px;
	padding: 2px 10px;
	border-radius: 3px;
	border: 1px solid var(--bs-gray-300);
	font-size: 13px;

	button {
		position: absolute;
		top: -5px;
		right: -5px;
		background: red;
		color: white;
		border-radius: 50%;
		border: 0;
		width: 15px;
		height: 15px;
		font-size: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

let axiosCancelToken = null;

export default function UsersForm() {
	const navigate = useNavigate();
	const params = useParams();

    const dispatch = useStore(state => state.dispatch);

   	let [stations, setStations] = useState([]);
	let [capabilities, setCapabilities] = useState([]);
	let [data, setData] = useState({
		capabilities: {},
		stations: ['all'],

	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getAvailableCapabilities = async () => {
			return axios.get('api/users/get-available-capabilities', {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setCapabilities(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getAvailableCapabilities();

		const getAvailableStations = async () => {
			axios.get('/api/stations/list', {
				params: {
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setStations(response.data);
		  	}).catch((error) => {			
				if ( axios.isCancel(error) ) return;
			});	
		}
		getAvailableStations();

		if ( params.id ) {
			const getData = async () => {
				return axios.get('api/users/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData((prev) => ({...prev, ...response.data}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getData();
		}
	}, [params.id]);

	const saveData = async (exit = false) => {
		setErrors({});

		await axios.post('api/users/' + (params.id ? 'edit/' + params.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			if ( getUser()?.id === data.id ) {
				dispatch({
					type: 'setForceReload'
				});
			}
			if ( exit ) navigate('/users');
			else setData((prev) => ({...prev, ...response.data.user}));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const deleteUser = () => {
		const c = window.confirm('¿Seguro que quieres eliminar este usuario?');
		if ( !c ) return false;

		axios.delete('api/users/delete/' + data.id, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Usuario eliminada');
			navigate('/users');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al eliminar');
		});	
	}

	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const setCapability = (key, action, status) => {
		let capabilities = {...data.capabilities};
		if ( !capabilities[key] ) capabilities[key] = {read: 0, add: 0, edit: 0, delete: 0};
		capabilities[key][action] = status ? 1 : 0;
		setDataField('capabilities', capabilities);
	}

	const addStation = (station) => {
		// Assoc all
		if ( station.id === 'all' ) {
			setData({...data, stations: ['all']});
			return;
		}

		// Assoc single
		let stations = data.stations.indexOf('all') !== -1 ? [] : [...data.stations];
		stations.push(station.id);
		setData({...data, stations: stations});
	}

	const removeStation = (idx) => {
		let stations = [...data.stations];
		stations.splice(idx, 1);
		setData({...data, stations: stations});
	}

	const loadStations = (input, callback, args) => {
		axios.get('/api/stations/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: el.name
				};
			});

			formatted.unshift({
				value: 'all',
				label: <div className="text-center">Asociar todas</div>
			});

			formatted = formatted.filter((el) => {
				if ( args.data.stations.indexOf(el.value) !== -1 ) return false; // If added, prevent show on dropdown
				return true;
			});

			callback(formatted);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	
	}

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-primary text-white text-uppercase p-2">
					Ficha de usuario
					<NavLink to="/users" className="btn btn-sm btn-light p-0 px-2 float-end">Volver</NavLink>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<div className="card shadow-sm">
					<div className="card-body">
						<div className="row">
							<div className="col-md-6">
								<div className="row">
									<div className="col-md-6">
										<div className="mb-2">
											<label htmlFor="name">Nombre</label>
											<input type="text" id="name" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
											{ errors.name &&
												<div className="invalid-feedback d-block">{ errors.name[0] }</div>
											}
										</div>
									</div>
									<div className="col-md-6">
										<div className="mb-2">
											<label htmlFor="email">Email</label>
											<input type="email" id="email" className="form-control form-control-sm" onChange={(e) => setDataField('email', e.target.value)} value={data.email ?? ''} />
											{ errors.email &&
												<div className="invalid-feedback d-block">{ errors.email[0] }</div>
											}
										</div>
									</div>
									<div className="col-md-6">
										<div className="mb-2">
											<label htmlFor="password">Contraseña</label>
											<input type="text" id="password" className="form-control form-control-sm" onChange={(e) => setDataField('password', e.target.value)} value={data.password ?? ''} placeholder={data.id ? '******' : 'Escribe una contraseña'} />
											{ data.id &&
												<FieldSmallComment className="mt-1">Dejar en blanco si no se quiere modificar la contraseña</FieldSmallComment>
											}
											{ errors.password &&
												<div className="invalid-feedback d-block">{ errors.password[0] }</div>
											}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<LabeledFrame label="Listado de permisos" className="mb-3">
									<CapabilitiesTable className="table table-sm table-bordered mb-0">
										{jsonToArray(capabilities).map((type, typeIdx) => {
											return (
												<React.Fragment key={typeIdx}>
													<thead className="type">
														<tr>
															<th colSpan="100%">{type.value.name}</th>
														</tr>
													</thead>
													<thead>
														<tr>
															<th></th>
															<th>Ver</th>
															<th>Añadir</th>
															<th>Modificar</th>
															<th>Eliminar</th>
														</tr>
													</thead>
													<tbody>
														{jsonToArray(type.value.capabilities).map((capability, capabilityIdx) => {
															return (
																<tr key={capabilityIdx}>
																	<td>{capability.value}</td>
																	<td>
																		<div className="form-check form-switch">
																			<input type="checkbox" className="form-check-input" checked={data.capabilities[capability.key]?.read === 1} onChange={(e) => setCapability(capability.key, 'read', e.target.checked)} />
																		</div>
																	</td>
																	<td>
																		<div className="form-check form-switch">
																			<input type="checkbox" className="form-check-input" checked={data.capabilities[capability.key]?.add === 1} onChange={(e) => setCapability(capability.key, 'add', e.target.checked)} />
																		</div>
																	</td>
																	<td>
																		<div className="form-check form-switch">
																			<input type="checkbox" className="form-check-input" checked={data.capabilities[capability.key]?.edit === 1} onChange={(e) => setCapability(capability.key, 'edit', e.target.checked)} />
																		</div>
																	</td>
																	<td>
																		<div className="form-check form-switch">
																			<input type="checkbox" className="form-check-input" checked={data.capabilities[capability.key]?.delete === 1} onChange={(e) => setCapability(capability.key, 'delete', e.target.checked)} />
																		</div>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</React.Fragment>
											)
										})}
									</CapabilitiesTable>
									{ errors.capabilities &&
										<div className="invalid-feedback d-block">{ errors.capabilities[0] }</div>
									}
								</LabeledFrame>

								<LabeledFrame 
									label="Estaciones asociadas"
								>
									<StationsWrapper className="mt-2">
									<div className="row">
											<div className="col-md-4 ms-auto">
												<EmpoweredSelector
													load={(input, callback, args) => loadStations(input, callback, args)}
													args={{data}}
													onChange={(value, text) => addStation({id: value, name: text})}
													timeout={200}
													placeholder={'Añadir estación'}
													value={null}
												/>
											</div>
											<div className="col-md-12 mt-3">
												{data.stations?.map((el, idx) => {
													return (
														<Station key={idx} className="station">
															{el === 'all' ? 
																<div>Este usuario tiene acceso a todas las estaciones</div>
																:
																stations.filter(sEl => sEl.id === el)[0]?.name
															}
															<button onClick={() => removeStation(idx)}><i className="bi bi-x"></i></button>
														</Station>
													);
												})}
												{data.stations?.length === 0 &&
													<Station>No hay estaciones asociadas</Station>
												}
											</div>
										</div>
									</StationsWrapper>
									{/*{ errors.stations &&
										<div className="invalid-feedback d-block">{ errors.stations[0] }</div>
									}*/}
								</LabeledFrame>
							</div>
						</div>
					</div>
					<div className="card-footer d-flex justify-content-between">
						{ (data.id && hasCapability('users', 'delete')) ?
							<button className="btn btn-sm btn-link text-danger" onClick={() => deleteUser()}>Eliminar</button>
							:
							<span>&nbsp;</span>
						}

						{ (hasCapability('users', 'add') || (data.id && hasCapability('users', 'edit'))) &&
							<div>
								<button className="btn btn-sm btn-plenoil-primary me-3" onClick={() => saveData()}>Guardar</button>
								<button className="btn btn-sm btn-plenoil-primary" onClick={() => saveData(true)}>Guardar y salir</button>
							</div>
						}
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


