import React, { useEffect, useState } from 'react';
import { useSearchParams, useParams, Navigate } from "react-router-dom";
import LoggedLayout from 'layouts/LoggedLayout';
import PageHeader from 'components/PageHeader';
import { default as ClockingsService } from 'services/Clockings';
import TopSection from './TopSection';
import MonthsTable from './MonthsTable';

export default function Dispenser(props) {
	const [queryParams] = useSearchParams();
	const { id } = useParams();

	const date = queryParams.get('schedule_day');

	const [dispenser, setDispenser] = useState(null);

	useEffect(() => {
		if ( !id || !date ) return;

		let getDispenser = async () => {
			let dispenser = await ClockingsService.getDispenser(id, {
				with_responsibles: true
			});
			setDispenser({...dispenser});
		}
		getDispenser();
	}, [id, date]);


	if (!date) return <Navigate to={'/clockings/dispensers'} />;

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-1">
				<PageHeader style={{overflowX: 'visible', overflowY: 'visible'}}>
					<span className="header-column header-title">Control de turnos</span>
					<span className="header-column header-icon-end">
						{/* <i className="bi bi-printer text-plenoil-purple"></i> */}
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-purple text-white">
					<div className="row">
						<div className="col-md-12 px-4 py-2">
							&nbsp;
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-12 px-3 mb-3">
				<TopSection 
					dispenser={dispenser} 
					date={date}
				/>
			</div>
			<div className="col-md-12 px-3">
				<MonthsTable 
					dispenser={dispenser}
				/>
			</div>
		</LoggedLayout>
	);
}