import styled from 'styled-components';

const PageHeaderStyled = styled.div`
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--bs-gray-500);
    overflow-x: auto;
    overflow-y: hidden;
    
    @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
    }

    .header-column {
        display: flex;
        align-items: center;
        border-right: 1px solid var(--bs-gray-500);
        padding-left: 10px;
        padding-right: 10px;

        @media (max-width: 992px) {
            padding: 0;
            margin-bottom: 10px;   
            border: 0;
            padding: 0 !important;
        }

        &.header-title {
            text-transform: uppercase;
            color: var(--bs-gray-700);
            font-size: 20px;
            padding-left: 0;
            padding-right: 40px;
        }
        
        &.header-icon-end {
            margin-left: auto;
            border-right: 0;
            font-size: 30px;
            line-height: 13px;
            color: var(--plenoil-blue);

            @media (max-width: 992px) {
                display: none;
            }
        }

        .header-column-text {
            text-transform: uppercase;
            color: var(--bs-gray-500);
            font-size: 13px;
            white-space: nowrap;
        }

        .header-column-number {
            font-size: 30px;
            line-height: 13px;
            text-align: right;
        }
    }
`;

export default function PageHeader(props) {

	return (
        <PageHeaderStyled style={props.style}>
            {props.children}
        </PageHeaderStyled>
	);
}