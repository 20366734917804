import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { ucfirst } from "helpers/generic";

const StyledElement = styled.div` 
	position: absolute;

	background: white;
	top: -15px;
	left: 105%;
	padding: 10px;
	border-radius: 5px;
	border: 3px solid var(--bs-gray-300);
	z-index: 9999;

	table {
        width: 400px;
        min-width: 400px;
        max-width: 400px;

		tbody > tr {
			&:not(:first-of-type) {
				td {
					padding-top: 10px;
				}
			}

			&:not(:last-of-type) {
				td {
					padding-bottom: 10px;
				}
			}

			td {
				border-bottom: 1px solid var(--bs-gray-300);
				text-align: left !important;
			}
		}

		tbody > tr > td > table > tbody > tr > td {
			font-size: 11px !important;
			border-bottom: 0 !important;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}

    .ocr_popup_editable_wrapper {
        display: flex;
        width: 130px;
        position: relative;

        &:hover {
            background: #f9f9f9;
        }

        button {
            position: absolute;
            right: -10px;
            top: -8px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: none;
            font-size: 8px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: lightgray;
            
            &:hover {
                transform: scale(1.3);
            }

            &.editing {
                background: var(--plenoil-green);
                color: white;
            }
        }

        input:not(button) {
            width: 100%;
            padding: 0;
            min-height: 0 !important;
            height: auto !important;
            min-width: auto !important;
            font-size: 12px;

            &::-webkit-inner-spin-button,
            &::-webkit-calendar-picker-indicator {
                display: none;
                -webkit-appearance: none;
            }
        }
    }

	.ocr_log {
		white-space: wrap;
		font-size: 10px;
		line-height: 10px;

		div {
			margin-top: 3px;
		}
	}
`;

let axiosCancelToken = null;
let editingFix = false;

const OcrPopup = React.memo((props) => {
    let id = props.id;
    let data = props.data;
    let parentForceReload = props.forceReload;

    let goaQtyAlert = props.goaQtyAlert;
    let sp95QtyAlert = props.sp95QtyAlert;

    let sentAt = props.sentAt;
    let sentLog = props.sentLog;

    let [number, setNumber] = useState(data?.data?.codAlbaran ?? (data?.data?.nDocumento ?? '') ?? '');
    let [editingNumber, _setEditingNumber] = useState(false);
    let setEditingNumber = (status) => {
        _setEditingNumber(status);
        if ( !status ) {
            saveField('number', number);
            editingFix = false;
        } else {
            editingFix = true;
        }
    }

    let [date, setDate] = useState(moment(data?.data?.fecha ?? (data?.data?.fechaHora ?? '')).format('YYYY-MM-DD HH:mm'));
    let [editingDate, _setEditingDate] = useState(false);
    let setEditingDate = (status) => {
        _setEditingDate(status);
        if ( !status ) {
            saveField('date', date);
            editingFix = false;
        } else {
            editingFix = true;
        }
    }

    let [goaLiters, setGoaLiters] = useState(data?.data?.goa_liters ?? data?.data?.items?.reduce((carry, item) => carry += item.combustible === 'goa' ? parseFloat(item.cantidadDeclarada) : 0, 0));
    let [editingGoaLiters, _setEditingGoaLiters] = useState(false);
    let setEditingGoaLiters = (status) => {
        _setEditingGoaLiters(status);
        if ( !status ) {
            saveField('goa_liters', goaLiters);
            editingFix = false;
        } else {
            editingFix = true;
        }
    }

    let [sp95Liters, setSp95Liters] = useState(data?.data?.sp95_liters ?? data?.data?.items?.reduce((carry, item) => carry += item.combustible === 'sp95' ? parseFloat(item.cantidadDeclarada) : 0, 0));
    let [editingSp95Liters, _setEditingSp95Liters] = useState(false);
    let setEditingSp95Liters = (status) => {
        _setEditingSp95Liters(status);
        if ( !status ) {
            saveField('sp95_liters', sp95Liters);
            editingFix = false;
        } else {
            editingFix = true;
        }
    }

    let [bypassLimits, _setBypassLimits] = useState(data?.data?.bypass_limits ?? false);
    let setBypassLimits = (status) => {
        _setBypassLimits(status);
        saveField('bypass_limits', status);
    }

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    const saveField = (fieldName, fieldValue) => {
        axios.post('/api/tankertrucks/update-ocr-field/' + id, 
			{
                field: fieldName,
                value: fieldValue
            }, 
			{ cancelToken: axiosCancelToken.token }
		).then((response) => {

		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error');
		});	
    }

    const resendOcr = (e) => {
        e.stopPropagation();

        editingFix = false; // Fix to allow parent render update this component

		axios.post('/api/tankertrucks/resend-ocr/' + id, 
			{}, 
			{ cancelToken: axiosCancelToken.token }
		).then((response) => {
			parentForceReload();
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error');
		});	
	}

    return (
        <StyledElement className="ocr-details-preview">
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan="3" className="pe-3"><b>Distribuidor:</b> {ucfirst(data?.type ?? '')}</td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="pe-3">
                                <div className="d-flex">
                                    <b>ALBARÁN Nº:</b>&nbsp;
                                    <span className="ocr_popup_editable_wrapper">
                                        { editingNumber ?
                                            <>
                                                <button className="editing" onClick={() => setEditingNumber(false)}><i className="bi bi-check"></i></button>
                                                <input type="text" value={number} tabIndex="1" onChange={(e) => setNumber(e.target.value)} />
                                            </>
                                            :
                                            <>
                                                <button onClick={() => setEditingNumber(true)}><i className="bi bi-pencil"></i></button>
                                                {number}
                                            </>
                                        }
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <b>FECHA:</b> 
                                    <span className="ocr_popup_editable_wrapper">
                                        { editingDate ?
                                            <>
                                                <button className="editing" onClick={() => setEditingDate(false)}><i className="bi bi-check"></i></button>
                                                <input type="datetime-local" value={date} tabIndex="1" onChange={(e) => setDate(e.target.value)} />
                                            </>
                                            :
                                            <>
                                                <button onClick={() => setEditingDate(true)}><i className="bi bi-pencil"></i></button>
                                                {date ? moment(date).format('DD/MM/YYYY HH:mm') : ''}
                                            </>
                                        }
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="pe-3"><b>TOTAL</b></td>
                            <td className={'pe-3 ' + (goaQtyAlert ? ' bg-warning ' : '')}>
                                <div className="d-flex">
                                    <b>Cantidad GOA:</b>
                                    <span className="ocr_popup_editable_wrapper" style={{maxWidth: '100px'}}>
                                        { editingGoaLiters ?
                                            <>
                                                <button className="editing" onClick={() => setEditingGoaLiters(false)}><i className="bi bi-check"></i></button>
                                                <input type="number" step="1" value={goaLiters} tabIndex="1" onChange={(e) => setGoaLiters(e.target.value)} />
                                            </>
                                            :
                                            <>
                                                <button onClick={() => setEditingGoaLiters(true)}><i className="bi bi-pencil"></i></button>
                                                {goaLiters}
                                            </>
                                        }
                                    </span>
                                </div>
                            </td>
                            <td className={'pe-3 ' + (sp95QtyAlert ? ' bg-warning ' : '')}>
                                <div className="d-flex">
                                    <b>Cantidad SP:</b>
                                    <span className="ocr_popup_editable_wrapper" style={{maxWidth: '100px'}}>
                                        { editingSp95Liters ?
                                            <>
                                                <button className="editing" onClick={() => setEditingSp95Liters(false)}><i className="bi bi-check"></i></button>
                                                <input type="number" step="1" value={sp95Liters} tabIndex="1" onChange={(e) => setSp95Liters(e.target.value)} />
                                            </>
                                            :
                                            <>
                                                <button onClick={() => setEditingSp95Liters(true)}><i className="bi bi-pencil"></i></button>
                                                {sp95Liters}
                                            </>
                                        }
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3">
                                <div className="pb-1"><small><b>DETALLE</b></small></div>
                                <table>
                                    <tbody>
                                        {data?.data?.items?.map((ocrItem, ocrIdx) => {
                                            return (
                                                <tr key={ocrIdx}>
                                                    <td className="pe-2"><b>COMBUSTIBLE:</b> {ocrItem.combustible.toUpperCase()}</td>
                                                    <td className="pe-2"><b>LITROS:</b> {ocrItem.cantidadDeclarada}</td>
                                                    <td className="pe-2"><b>DENSIDAD:</b> {ocrItem.densidad ?? '-'}</td>
                                                    <td><b>TEMPERATURA:</b> {ocrItem.temperatura ?? '-'}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="mt-4">
                    <div className="d-flex">
                        <b>Enviado a BC:</b>&nbsp;{sentAt ? moment(sentAt).format('DD-MM-YYYY HH:mm') : <span className="badge bg-danger">NO</span>}
                        {sentAt && 
                            <button className="btn btn-secondary btn-sm p-0 px-2 ms-2" onClick={(e) => resendOcr(e)}>Reenviar</button>
                        }
                    </div>

                    <div>
                        {(sentAt || bypassLimits) && 
                            <label className="d-flex user-select-none align-items-center">
                                <input type="checkbox" checked={bypassLimits} onChange={(e) => setBypassLimits(!bypassLimits)} disabled={!sentAt} />
                                Ignorar límite 1000 litros
                            </label>
                        }
                    </div>
                    
                    {sentAt &&
                        <div className="ocr_log">
                            {(sentLog && sentLog['gfu_GetPedido'] && sentLog['gfu_GetPedido']['message']) && <div><b>gfu_GetPedido</b>: {sentLog['gfu_GetPedido']['message']}</div>}
                            {(sentLog && sentLog['gfu_RegistrarPedido'] && sentLog['gfu_RegistrarPedido']['message']) && <div><b>gfu_RegistrarPedido</b>: {sentLog['gfu_RegistrarPedido']['message']}</div>}
                            {(sentLog && sentLog['custom_message']) && <div className="invalid-feedback d-block">{sentLog['custom_message']}</div>}
                        </div>
                    }
                </div>
            </div>
        </StyledElement>
    );
}, (a, b) => {
    if ( editingFix ) return true; // Prevent rerenders from parent updates
    return false;
});

export default OcrPopup;