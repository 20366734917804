import React, { useEffect, useState } from 'react';
import LoggedLayout from '../../layouts/LoggedLayout';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as PricesService } from 'services/Prices';
import { default as StationsService } from 'services/Stations';

const Table = styled.table`
	th,
	td {
		vertical-align: middle;
		position: relative;

		&:nth-child(1) {
			text-align: center;
			width: 20px;
		}

		&:nth-child(2) {
			text-align: left;
		}

		&:nth-child(3) {
			width: 100px;
		}

		&:nth-child(4) {
			width: 100px;
		}
	}
`;

const Filters = styled.div`
	display: flex;
	justify-content: end;

	@media (max-width: 1200px) {
		justify-content: center;
		flex-direction: column;

		& > *,
		.empowered-selector {
			width: 100% !important;
			display: block !important;
		}

		& > * {
			margin-bottom: 10px;
		}
	}
`;

const EmpoweredSelectorWrapper = styled.div` 
	margin-right: 10px;

	.empowered-selector {
		width: 200px;
		
		.empowered-selector_label {
			height: 28px;
			white-space: nowrap;
			overflow: hidden;

			&:hover {
				background: white;
			}

			.remove-icon {
				background: white;
				border-radius: 50%;
			}
		}
	}
`;

export default function PricesApp() {
	let [prices, setPrices] = useState([]);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('station_name');
	let [station, setStation] = useState(null);
	let [skeletonRows, setSkeletonRows] = useState(5);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	const loadStations = async (input, callback) => {
		let stations = await StationsService.getList({
			search: input,
			no_paginate: true
		});
		
		callback(stations?.map(el => {
			return {
				value: el,
				label: el.name
			}
		}));
	}

	useEffect(() => {

		return function cleanup() {
			PricesService.cancel();
			StationsService.cancel();
        }
	}, []);

	useEffect(() => {
		const getPrices = async () => {
			let prices = await PricesService.getAppPricesList({
				station_id: station?.id,
				sort: sortField,
				direction: sortDirection
			});

			setPrices([...prices]);
			setSkeletonRows(prices?.length);
		};
		getPrices();

		let reloadInterval = setInterval(() => {
			getPrices();
		}, 5000);

		return function cleanup() {
			clearInterval(reloadInterval);
		}
	}, [station, sortDirection, sortField]);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader>
					<span className="header-column header-title">Precios App</span>
					
					<span className="header-column header-icon-end">
                        <i className="bi bi-droplet-half text-plenoil-red"></i>
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-red text-white p-2">
					<div className="row">
						<div className="col-md-2">
						</div>
						<Filters className="col-md-10">
							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback) => loadStations(input, callback)}
									onChange={(value, text) => setStation(value)}
									timeout={200}
									label={station?.name}
									value={station?.id ?? null}
									placeholder="Estación"
								/>
							</EmpoweredSelectorWrapper>
						</Filters>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<div className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'station_id'} onClick={() => sortTableClick('station_id')}>#</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'station_name'} onClick={() => sortTableClick('station_name')}>Estación</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'goa_price'} onClick={() => sortTableClick('goa_price')}>GOA</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'sp95_price'} onClick={() => sortTableClick('sp95_price')}>SP95</ThSortable>
							</tr>
						</thead>
						<tbody>
							{ prices.map((el, idx) => {
								return (
									<tr key={idx}>
                                        <td>{el.station_id}</td>
                                        <td>{el.station_name}</td>
                                        <td>{el.goa_price ? parseFloat(el.goa_price).toFixed(3) : '-'}</td>
                                        <td>{el.sp95_price ? parseFloat(el.sp95_price).toFixed(3) : '-'}</td>
                                    </tr>
								)
							}) }

							{ prices && !prices.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ prices === undefined && <TrSkeleton rows={skeletonRows} columns={3} /> }
						</tbody>
					</Table>
				</div>
			</div>
		</LoggedLayout>
	);
}