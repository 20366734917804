import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import resizeImage from 'resize-image';
import ChatService from 'services/Chat';
import Message from './Message';
import { jsonToArray } from 'helpers/generic';
import { getUser } from 'helpers/user';
import NotificationLightAudio from './notification_light.mp3';

const MessagesWindowStyled = styled.div`
    position: absolute;
    top: -5%;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 110%;
    background: white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: -5px 0px 15px var(--bs-gray-500);
    padding: 20px;

    @media (max-width: 500px) {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .close-button {
        position: absolute; 
        display: flex;
        justify-content: center;
        align-items: center;
        top: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
        border: 0;
        background: 0;
        border-radius: 50%;

        &:hover {
            background: var(--bs-gray-100);
        }
    }

    .station-name {
        display: flex;
        align-items: center;
        font-size: 23px;
        line-height: 23px;
        font-weight: 400;
        height: 30px;

        .badge {
            margin-left: 5px;
            padding: 2px 5px;
            font-size: 18px;
            font-weight: 300;
            text-transform: uppercase;
        }
    }

    .messages-container {
        height: 100%;
        margin-top: 10px;
        margin-bottom: 5px;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 5px;
        background: var(--bs-gray-100);
        border: 2px solid var(--bs-gray-300);
        padding: 5px;
    }
    
    .textbox {
        height: 100px;

        textarea {
            resize: none;
            font-size: 11px;
            line-height: 12px;
        }
    }

    .upload-image-wrapper {

        input[type=file] {
            height: 0;
            width: 0;
            opacity: 0;
        }
    }
`;

let last_seen_message_id = null;
let reading = false;
let notificationLightAudio = new Audio(NotificationLightAudio);
notificationLightAudio.volume = 0.3;

const MessagesWindow = React.memo((props) => {
    const close = props.close;
    const station = props.station;
    const parentOpened = props.parentOpened;

    const fileRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const lastMessageRef = useRef(null);

    let [errors, setErrors] = useState([]);
    let [messages, setMessages] = useState([]);
    let [sending, setSending] = useState(false);
    let [message, setMessage] = useState('');

    const getMessages = useCallback(async () => {
        // Prevent reading messages if currently reading
        if ( reading ) {
            // Try again after some milliseconds
            setTimeout(() => {
                getMessages();
            }, 500);

            return;
        };

        reading = true;

        let newMessages = await ChatService.getMessages(station.id, station.channel.id, last_seen_message_id);
        if ( newMessages && newMessages.length > 0 ) {
            // Play sound
            let newMessagesDifferentFromMe = newMessages.filter(el => el.seen_at === null && el.user_id !== getUser()?.id).length > 0;
            if ( last_seen_message_id && newMessagesDifferentFromMe && parentOpened ) {
                notificationLightAudio.play();
            }

            // Set messages
            setMessages(prev => [...prev, ...newMessages]);
            last_seen_message_id = [...newMessages].pop()?.id;
        }

        reading = false;
    }, [station.id, station.channel.id, parentOpened]);

    const sendMessage = async () => {
        if ( sending ) return; // Prevent send if currently sending

        setErrors([]);
        setSending(true);
        
        let result = await ChatService.sendMessage(station.id, station.channel.id, message);
        if ( result && result.status ) {
            setMessage('');
            getMessages();
        } else {
            let errorsFormatted = jsonToArray(result).map(el => el.value[0] ?? null).filter(el => el !== null);
            setErrors(errorsFormatted);
        }

        setSending(false);
    }

    const uploadImage = async (event) => {
        let file = event.target.files[0];

        // Check extension
        let nameSplit = file.name.split('.');
        let extension = nameSplit.pop();
        if ( ['jpg', 'jpeg', 'png', 'bmp', 'gif'].indexOf(extension) === -1 ) {
            setErrors(['El fichero de imágen tiene un formato inválido']);
            return;
        }

        // Read file
        var reader = new FileReader();
        reader.onload = () => {            
            var dataUrl = reader.result;
            
            // Change size to image
            let img = new Image();
            img.onload = async () => {
                let data = resizeImage.resize(img, 512, null, resizeImage.JPEG);

                // Convert to blob
                let blob = await fetch(data).then(res => res.blob());
                
                // Upload
                setErrors([]);
                setSending(true);
                let result = await ChatService.uploadImage(station.id, station.channel.id, blob);
                if ( result.status === 1 ) {
                    getMessages();
                } else {
                    let errorsFormatted = jsonToArray(result).map(el => el.value[0] ?? null).filter(el => el !== null);
                    setErrors(errorsFormatted);
                }
                setSending(false);
            }
            img.src = dataUrl;
        };
        reader.readAsDataURL(file);
    }

    useEffect(() => {
        const getMessagesInterval = setInterval(() => {
            getMessages();
        }, 2000);

        setMessages([]); // First load set empty messages (needed when developing for hot reloading)
        getMessages();
       
        return function cleanup() {
            clearInterval(getMessagesInterval);
            last_seen_message_id = null;
            reading = false;
        }
    }, [getMessages]);

    useEffect(() => {
        let lastUnseenMessage = messages.filter(el => el.seen_at === null && el.user_id !== getUser()?.id).pop();
        if ( lastUnseenMessage ) {
            ChatService.readMessage(lastUnseenMessage.id);
        }
    }, [messages]);

	return (
		<MessagesWindowStyled id="chat-messages-window">
            <button className="close-button" onClick={close}><i className="bi bi-x"></i></button>
            <div className="station-name">
                {station.name} 
                <span className="badge" style={{background: station.channel?.color}}>{station.channel?.name}</span>
            </div>
            <div className="messages-container" ref={messagesContainerRef}>
                {messages.map((el, idx) => {
                    return (
                        <Message 
                            key={idx} 
                            ref={idx === messages.length-1 ? lastMessageRef : null} 
                            {...el}
                            parentBoundingClientRect={messagesContainerRef.current.getBoundingClientRect()}
                        />
                    );
                })}
                {errors?.map((el, idx) => {
                    return (
                        <div key={'e'+idx} className="text-danger">{el}</div>
                    )
                })}
            </div>
            <div className="textbox">
                <div className="input-group d-flex mb-3">
                    <textarea 
                        type="text" 
                        className="form-control" 
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if ( e.key === 'Enter' ) {
                                sendMessage();
                                e.preventDefault();
                            } 
                        }} 
                        rows="4"
                    />
                    <button className="btn btn-plenoil-primary" type="button" disabled={sending || message.trim().length === 0} onClick={sendMessage}>Enviar</button>
                </div>
                
                <div className="upload-image-wrapper">
                    <button 
                        className="btn btn-sm btn-link text-plenoil-primary p-0" 
                        onClick={() => {
                            fileRef.current.value = null;
                            fileRef.current.click();
                        }}
                    >
                        Imagen
                    </button>
                    <input type="file" ref={fileRef} onChange={uploadImage}  />
                </div>
            </div>
		</MessagesWindowStyled>
	);
}, (prevProps, nextProps) => {
    if ( prevProps.station === nextProps.station ) return true; // Prevent re-render if station is not changed
    return false;
});

export default MessagesWindow;