import styled from 'styled-components';

const Th = styled.th`
	cursor: pointer;
	white-space: nowrap;
	user-select: none;

	&.active {
		color: #333 !important;

		div {
			display: flex;
			justify-content: space-between;
			align-items: center;

			span {
				margin-left: 5px;
				font-size: 80%;
			}
		}
	}
`;

export default function ThSortable(props) {
	return (
		<Th 
			onClick={() => props.onClick()}
			className={(props.direction ?? 'asc') + ' ' + ((props.active ?? false) ? 'active' : '')} 
			style={props.style ?? {}}
		>
			<div>
				{ props.children }
				{ props.active && <span>{props.direction === 'asc' ? '▲' : '▼'}</span> }
			</div>
		</Th>
	);
}