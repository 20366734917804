import axios from 'axios';

export default class Probes {

	static axiosCancelToken = axios.CancelToken.source();
	
	static listZones = (search) => {
		this.newAxiosCancelToken();

		return axios.get('/api/zones/list', {
			params: {
				search: search
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static updateOctanTankCapacityReal = (tankId, capacity) => {
		this.newAxiosCancelToken();

		return axios.post('api/probes/update-octan-tank-capacity-real', {
			tank_id: tankId,
			capacity: capacity
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}

	static get = (station_id, attempt = 1) => {
		this.newAxiosCancelToken();

		return axios.get('api/probes/get/' + station_id, {
			params: {
				attempt: attempt
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			
			return {
				error: error.response.data.message ?? 'Undefined error'
			};
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}