import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import LoggedLayout from '../../layouts/LoggedLayout';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import TrSkeleton from 'components/TrSkeleton';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as ClockingsService } from 'services/Clockings';
import { default as StationsService } from 'services/Stations';
import { cristalLoader } from 'helpers/generic';

const TableResponsive = styled.div` 
	@media (min-width: 992px) {
		overflow-x: visible;
	}
`;

const Table = styled.table`
	tr {
		th {
			width: 50%;
		}
	}
`;

const SaveBtn = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;

    &:hover {
        background: var(--plenoil-blue) !important;
        color: white !important;
    }
`;

export default function StationsMatching(props) {
	let [loading, setLoading] = useState(false);
	let [stations, setStations] = useState([]);
	let [tree, setTree] = useState([]);
	let [matchings, setMatchings] = useState({});
	let [skeletonRows, setSkeletonRows] = useState(5);

	useEffect(() => {
		let getData = async () => {
            setLoading(true);

			let stations = await StationsService.getList({
				no_paginate: true,
                sort: 'name',
                direction: 'asc'
			});

		    let tree = await ClockingsService.getClockingCompanyTree();
            setTree(tree);

		    let matching = await ClockingsService.getStationsMatching();
            setMatchings(matching);

            setLoading(false);

			setSkeletonRows(stations?.length);
			setStations([...stations]);
		}
		getData();
	}, []);

    const saveData = async () => {
        setLoading(true);
        let result = await ClockingsService.saveStationsMatching(matchings);
        setLoading(false);

        if ( result.errors ) {
            toast.error('No se han guardado los datos');
        } else {
            toast.success('Se han guardado los datos');
        }
    }

    const loadBizneoTree = async (input, callback, args) => {
		let formatted = args.tree?.filter(el => input?.length > 0 ? el.name.toLowerCase().indexOf(input.toLowerCase()) !== -1 : true).map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});

		callback(formatted);
	}

    const setMatching = async (oldBizneoStationId , newBizneoStationId, stationId) => {
        let newMatchings = {...matchings};

		delete newMatchings[oldBizneoStationId];

		if ( newBizneoStationId ) {
			newMatchings[newBizneoStationId] = stationId;
		}

        setMatchings(newMatchings);
    }

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-1">
				<PageHeader style={{overflowX: 'visible', overflowY: 'visible'}}>
					<span className="header-column header-title">Estaciones</span>
					<span className="header-column header-icon-end">
						{/* <i className="bi bi-printer text-plenoil-purple"></i> */}
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-purple text-white">
					<div className="row">
						<div className="col-md-12 px-4 py-2">
							Matching
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
                {loading && cristalLoader}
				<TableResponsive className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
                                <th>Estación Octan</th>
                                <th>Estación Bizneo</th>
							</tr>
						</thead>
						<tbody>
							{ stations?.map((el, idx) => {
								let treeId = Object.keys(matchings).find(key => matchings[key] === el.id);
								let stationId = matchings[treeId] ?? null;
							
                                let matchingLabel = null;
                                let matchingValue = null;

                                if ( treeId ) {
                                    matchingLabel = tree.filter(ml => ''+ml.id === ''+treeId)[0]?.name;
                                    matchingValue = stationId;
                                }

								return (
									<React.Fragment key={el.id}>
										<tr>
											<td>{el.name}</td> 
											<td>
                                                <EmpoweredSelector
                                                    load={(input, callback, args) => loadBizneoTree(input, callback, args)}
                                                    args={{tree: tree}}
                                                    onChange={value => setMatching(treeId, value?.id, el.id)}
                                                    label={matchingLabel}
                                                    value={matchingValue}
                                                    placeholder="Arbol"
                                                />
                                            </td> 
										</tr>
									</React.Fragment>
								)
							}) }
                            

							{ stations && !stations.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ stations === undefined && <TrSkeleton rows={skeletonRows} columns={2} /> }
						</tbody>
					</Table>
				</TableResponsive>
                <SaveBtn className="btn btn-plenoil-primary btn-lg" onClick={saveData}>Guardar</SaveBtn>
			</div>
		</LoggedLayout>
	);
}