import React from 'react';
import styled from 'styled-components';
import CompetitionPricesFrame from '../CompetitionPricesFrame';
import ScheduleForm from './ScheduleForm';

const DetailsStyled = styled.tr` 

    &:hover {
        background: inherit !important;
    }

    td {
        padding: 15px 40px;
        text-align: left !important;
        box-shadow: none !important;
        border-bottom: 2px solid var(--plenoil-blue);
    }

    .competition-frame {
        display: inline-flex;
        margin: 5px;
        border: 1px solid #dee2e6;
    }
`;

export default function Details(props) {
    let station = props.station;

    let stationCurrentPrices = props.stationCurrentPrices;
    let stationMineturPrices = props.stationMineturPrices;
    let stationAppPrices = props.stationAppPrices;

    return (
        <DetailsStyled>
            <td colSpan="100%">
                {stationMineturPrices.map((mEl, idx) => {
                    let singleCompetitionAppPrices = stationAppPrices[mEl.id] ?? null;

                    return (
                        <CompetitionPricesFrame 
                            key={'station-' + station.id + '-minetur-' + idx} 

                            competition={mEl} 
                            app={singleCompetitionAppPrices}
                            current={stationCurrentPrices} 

                            reloadParent={props.reloadParent}
                        />
                    )
                })}

                <ScheduleForm 
                    stationId={station.id}
                />
            </td>
        </DetailsStyled>
    );
}