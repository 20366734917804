import React, { useEffect, useState } from 'react';
import { useSearchParams, NavLink } from "react-router-dom";
import LoggedLayout from '../../layouts/LoggedLayout';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import Details from './Details';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as PricesIndicusWebserviceSendsService } from 'services/PricesIndicusWebserviceSends';

const TableResponsive = styled.div` 
	@media (min-width: 992px) {
		overflow-x: visible;
	}
`;

const Table = styled.table`
	tr {

		th {

			&:nth-child(1) {
			}

			&:nth-child(2) {
				width: 70px;
			}

			&:nth-child(3) {
				width: 60px;
			}

			&:nth-child(4) {
				width: 120px;
			}

			&:nth-child(5) {
				width: 120px;
			}

			&:nth-child(6) {
				width: 80px;
			}
		}
	}
`;

const Filters = styled.div`
	display: flex;
	justify-content: end;

	@media (max-width: 1200px) {
		justify-content: center;
		flex-direction: column;

		& > *,
		.empowered-selector {
			width: 100% !important;
			display: block !important;
		}

		& > * {
			margin-bottom: 10px;
		}
	}
`;

const EmpoweredSelectorWrapper = styled.div` 
	margin-right: 10px;

	.empowered-selector {
		width: 200px;
		
		.empowered-selector_label {
			height: 28px;
			white-space: nowrap;
			overflow: hidden;

			&:hover {
				background: white;
			}

			.remove-icon {
				background: white;
				border-radius: 50%;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function PricesIndicusWebserviceSends() {
	const [queryParams] = useSearchParams();

	let [forceReload, setForceReload] = useState(null);
	let [sends, setSends] = useState([]);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('created_at');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [details, setDetails] = useState({});
	let [station, setStation] = useState(null);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setSends({...sends, data: undefined});
		_setPage(page);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);

			// Remove details
			delete details[rowId];
			setDetails({...details});
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const loadStations = (input, callback) => {
		axios.get('/api/stations/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});

			callback(formatted);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		let getData = async () => {
			let sends = await PricesIndicusWebserviceSendsService.list({
				page: page,
				sort: sortField,
				direction: sortDirection,
				station_id: station?.id
			});
			setSkeletonRows(sends?.data?.length);
			setSends({...sends});
		}
		getData();

		let reloadInterval = setInterval(() => {
			getData();
		}, 5000);

		return function cleanup() {
			clearInterval(reloadInterval);
		}
	}, [forceReload, page, sortField, sortDirection, station]);

	const resend = async (e, data) => {
		e.stopPropagation();
		e.preventDefault();

		let result = await PricesIndicusWebserviceSendsService.resend(data.id);
		if ( result?.status ) {
			toast.success('Se procede a reenviar el precio');
			setForceReload(Math.random());
		} else {
			toast.error('Ha ocurrido un error al reenviar el precio');
		}
	}

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader>
					<span className="header-column header-title">Envíos a Indicus</span>
					<span className="header-column header-icon-end">
						<i className="bi bi-printer text-plenoil-black"></i>
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-black text-white p-2">
					<div className="row">
						<div className="col-md-2 text-uppercase">
							Registro de envíos
						</div>
						<Filters className="col-md-10">
							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback) => loadStations(input, callback)}
									onChange={(value, text) => setStation(value)}
									timeout={200}
									label={station?.name}
									value={station?.id ?? null}
									placeholder="Estación"
								/>
							</EmpoweredSelectorWrapper>
						</Filters>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<TableResponsive className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<th>Estación</th>
								<ThSortable direction={sortDirection} active={sortField === 'type'} onClick={() => sortTableClick('type')}>Producto</ThSortable>
								<th>Precio</th>
								<th>Fecha</th>
								<ThSortable direction={sortDirection} active={sortField === 'sent_at'} onClick={() => sortTableClick('sent_at')}>Envío</ThSortable>
								<th>Estado</th>
							</tr>
						</thead>
						<tbody>
							{ sends.data?.map((el, idx) => {
								return (
									<React.Fragment key={el.id}>
										<tr className={'has-details ' + (detailsOpened.includes(el.id) ? 'opened' : '')} onClick={() => toggleDetails(el.id)}>
											<td>
												<div className="d-flex justify-content-between">
													<span>{el.station_name}</span>
													{ el.error_at &&
														<button className="btn btn-sm btn-link p-0 text-danger ms-2" onClick={(e) => resend(e, el)}><i className="bi bi-envelope"></i></button>
													}
												</div>
											</td>
											<td>{el.type ?? ''}</td>
											<td>{el.price ?? ''}</td>
											<td>{el.date_start ? moment(el.date_start).format('DD-MM-YYYY HH:mm') : ''}</td>
											<td>{el.sent_at ? moment(el.sent_at).format('DD-MM-YYYY HH:mm') : ''}</td>
											<td>
												{(!el.sent_at && !el.error_at) ? <span className="badge bg-secondary">pendiente</span> : null}
												{(el.sent_at && !el.error_at) ? <span className="badge bg-success">enviado</span> : null}
												{(el.sent_at && el.error_at) ? <span className="badge bg-danger">error</span> : null}
											</td>
										</tr>
										{ detailsOpened.includes(el.id) &&
											<Details 
												data={el}
											/>
										}
									</React.Fragment>
								)
							}) }

							{ sends.data && !sends.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ sends.data === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
						</tbody>
					</Table>
				</TableResponsive>
			</div>

			<div className="col-md-12 text-end mb-3">
				<div className="d-inline-block">
					<Paginator
						min={1}
						current={sends?.current_page}
						max={sends?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</div>
			</div>
		</LoggedLayout>
	);
}