import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import LoggedLayout from '../../layouts/LoggedLayout';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import { default as ClockingsService } from 'services/Clockings';

const TableResponsive = styled.div` 
	@media (min-width: 992px) {
		overflow-x: visible;
	}
`;

const Table = styled.table`
	tr {
		cursor: pointer;
		
		th {

			&:nth-child(1) {
			}

			&:nth-child(2) {
				width: 20px;
			}
		}
	}
`;

export default function Responsibles(props) {
	const [queryParams] = useSearchParams();
    const navigate = useNavigate();

	let [responsibles, setResponsibles] = useState({});
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('name');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setResponsibles({...responsibles, data: undefined});
		_setPage(page);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	useEffect(() => {
		let getResponsibles = async () => {
			let responsibles = await ClockingsService.getResponsibles({
				page: page,
				sort: sortField,
				direction: sortDirection
			});
			setSkeletonRows(responsibles?.data?.length);
			setResponsibles({...responsibles});
		}
		getResponsibles();
	}, [page, sortField, sortDirection]);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-1">
				<PageHeader style={{overflowX: 'visible', overflowY: 'visible'}}>
					<span className="header-column header-title">Responsables</span>
					<span className="header-column header-icon-end">
						{/* <i className="bi bi-printer text-plenoil-purple"></i> */}
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-purple text-white">
					<div className="row">
						<div className="col-md-12 px-4 py-2">
							Listado de responsables
						</div>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<TableResponsive className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'user.name'} onClick={() => sortTableClick('user.name')}>Responsable</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'has_alert'} onClick={() => sortTableClick('has_alert')}>Alerta</ThSortable>
							</tr>
						</thead>
						<tbody>
							{ responsibles.data?.map((el, idx) => {
								return (
									<React.Fragment key={el.id}>
										<tr onClick={() => navigate('/clockings/dispensers?responsible_id=' + el.id)}>
											<td>{el.name}</td>
											<td>
												{el.has_alerts && <span className="text-danger"><i className="bi bi-asterisk"></i></span>}
											</td>
										</tr>
									</React.Fragment>
								)
							}) }

							{ responsibles.data && !responsibles.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ responsibles.data === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
						</tbody>
					</Table>
				</TableResponsive>
			</div>

			<div className="col-md-12 text-end mb-3">
				<div className="d-inline-block">
					<Paginator
						min={1}
						current={responsibles?.current_page}
						max={responsibles?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</div>
			</div>
		</LoggedLayout>
	);
}